import React, { Component } from 'react'
import styled from 'styled-components'

const H1 = styled.h1`
	transform: translateX(-0.05em);
	white-space: normal;

	span {
		display: block;
	}

	@media (min-width: 600px) {
		white-space: nowrap;
	}
`

class Title extends Component {
	render() {
		return (
			<H1>
				{ this.props.children }
			</H1>
		);
	}
}

export default Title
