import React from 'react'
import styled from 'styled-components'

const H2 = styled.h2`
	color: rgba(0, 0, 0, 0.5);
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke: 0.02em white;
	transform: translateX(-0.05em);
	white-space: nowrap;
`

export const Subtitle = ({ children }) => (
	<H2>{ children }</H2>
)

export default Subtitle
