/* General */
export const uiPadding = '7rem'

/* Holding */
export const holdingRed = 'hsl(10, 82%, 56%)'
export const holdingBlue = 'hsl(228, 76%, 54%)'

/* Header */
export const logoFont = '"Operator Black", "Helvetica Neue", sans-serif'
export const logoFontLineHeight = '0.95'
