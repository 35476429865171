import React, { Component } from 'react'
import styled from 'styled-components'
import * as Style from '../../config/styles'

// Components
import Title from './Title'
import Subtitle from './Subtitle'


const breakpoints = {
	medium: 1160,
}



const Header = styled.header`
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	min-height: 100vh;
	order: 1;
	position: relative;
	width: 100vw;
	z-index: 2;


	@media (min-width: ${breakpoints.medium}px) {
		order: 2;
		width: 50vw;
	}
`

const HGroup = styled.hgroup`
	background-color: rgb(249, 175, 72);
	display: flex;
	flex-direction: column;
	font-family: ${ Style.logoFont };
	font-size: 4.5rem;
	justify-content: flex-end;
	height: calc(50vh - ${ Style.uiPadding });
	height: auto;
	line-height: ${ Style.logoFontLineHeight };
	min-height: 50vh;
	padding: 22vh calc(${ Style.uiPadding } / 2) calc(${ Style.uiPadding } / 2);
	position: relative;
	transition: font-size 250ms ease-out;
	z-index: 2;

	@media (min-width: 600px) {
		font-size: 7rem;
		padding: ${ Style.uiPadding };
	}

	@media (min-width: 800px) {
		font-size: 10rem;
	}

	@media screen and (min-width: 1160px) and (min-height: 762px) {
		height: 50vh;
	}

	@media (min-width: 1160px) {
		font-size: 7rem;
	}

	@media screen and (min-width: 1440px) and (min-height: 762px) {
		height: 50vh;
	}

	@media (min-width: 1440px) {
		font-size: 10rem;
	}
`

const Details = styled.div`
	background-color: rgb(242, 91, 49);
	display: flex;
	flex-direction: column;
	font-size: 1.3rem;
	min-height: 50vh;
	padding: calc(${ Style.uiPadding } / 2);
	position: relative;
	z-index: 2;

	p { max-width: auto; }
	p + p { margin-top: 1em; }

	a {
		color: inherit;
		position: relative;
		text-decoration: none;
		white-space: nowrap;
	}

	a::after {
		background-color: rgb(220, 217, 201);
		bottom: -0.2em;
		content: "";
		height: 1px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	a:hover {
		color: rgb(21, 21, 21);
	}

	a:hover::after {
		background-color: rgb(21, 21, 21);
	}

	strong {
		font-family: "Operator Mono SSm Bold", monospace;
	}

	em {
		font-family: "Operator Mono SSm Book Italic", monospace;
	}

	strong em {
		font-family: "Operator Mono SSm Bold Italic", monospace;
		font-size: 1.15em;
	}

	.footer {
		margin-top: 1.5em;
	}

	@media (min-width: 600px) {
		font-size: 1.45rem;
		padding: ${ Style.uiPadding };
		p { max-width: 600px; }
	}

	@media (min-width: 800px) {
		font-size: 1.6rem;
	}

	@media (min-width: 1160px) {
		font-size: 1.45rem;
		padding: ${ Style.uiPadding };
		p { max-width: 500px; }
	}

	@media (min-width: 1440px) {
		font-size: 1.6rem;
		p { max-width: 600px; }
	}
`

export default class extends Component {
	script = () => {
		(function() {
			var e = document.createElement('script')
			e.type = 'text/javascript'
			e.async = true
			e.src = 'https://btn.createsend1.com/js/sb.min.js?v=3'
			e.className = 'createsend-script'
			var s = document.getElementsByTagName('script')[0]
			s.parentNode.insertBefore(e, s)
		})();
	}

	render() {
		return (
			<Header>
				<HGroup>
					<Title><span>The</span><span>New York City</span></Title>
					<Subtitle>User Guide</Subtitle>
				</HGroup>
				<Details>
					<p><strong><em>Does it drive you nuts when people block the subway doors? How about when they break open a tuna sandwich on the bus?</em></strong></p>
					<p>The New York City User Guide is a compendium of helpful guidelines for the ultimate enjoyment of New&nbsp;York&nbsp;City. A central place to collect all official and unofficial rules for the shared spaces in New York City. The subway, buses, sidewalks, and more. All content will be community driven&nbsp;and&nbsp;curated.</p>
					<p><strong>COMING SOON.</strong> We’re wrapping up development on the site and should be launching by the end of April.</p>
				</Details>
			</Header>
		);
	}
}
