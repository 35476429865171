import React from 'react'
import { render } from 'react-snapshot'
import registerServiceWorker from './registerServiceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './containers/App'
import 'reset-css'
import './index.css'

render(
	<Router>
		<App />
	</Router>,
	document.getElementById('root')
);
registerServiceWorker()
