import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { Helmet } from "react-helmet"
import ReactGA from 'react-ga'
import { debounce } from 'lodash'
import * as Style from '../../config/styles'

import OpenGraphImage from '../../assets/open-graph@2x.png'
import { version } from '../../../package.json'

import Masthead from '../../blocks/Masthead'
import TextWall from './TextWall'

ReactGA.initialize('UA-134264828-1')
ReactGA.pageview('/')


const breakpoints = {
	medium: 1160,
}


/**
 * Colors
 * rgb(52, 184, 137)
 * rgb(0, 123, 189)
 * rgb(226, 158, 37)
 * rgb(77, 109, 75)
 */

try {
	document.fonts.onloadingdone = () => {
		document.documentElement.classList.add('fonts-ready')
	}
	setTimeout(() => {
		document.documentElement.classList.add('fonts-ready')
	}, 800)
} catch (e) {
	document.documentElement.classList.add('fonts-ready')
}


const TextWallFlash = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`

const Wrapper = styled.main`
	align-items: stretch;
	background-color: white;
	background-color: rgb(241, 240, 239);
	box-shadow:
		0 0 40px 0 rgb(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: flex-start;
	left: 0;
	top: 0;
	transform-origin: right top;
	visibility: hidden;
	width: 100vw;
	will-change: transform;

	.fonts-ready & {
		visibility: visible;
	}

	.Version {
		color: rgba(255, 255, 255, 0.75);
		font-size: 11px;
		left: calc(${ Style.uiPadding } / 2);
		position: fixed;
		top: calc(${ Style.uiPadding } / 2);
		z-index: 3;
	}

	@media (min-width: 600px) {
		.Version {
			left: ${ Style.uiPadding };
			top: calc(${ Style.uiPadding } / 2);
		}
	}

	@media (min-width: ${breakpoints.medium}px) {
		flex-direction: row;

		.Version {
			left: calc(50vw + ${ Style.uiPadding });
		}
	}
`

const Ticker = styled.section`
	color: rgb(100, 100, 100);
	order: 2;
	overflow: hidden;
	position: relative;
	width: auto;

	.Interior {
		background-color: white;
		bottom: auto;
		color: rgb(225, 225, 225);
		height: 100vh;
		left: auto;
		overflow: hidden;
		position: relative;
		right: auto;
		top: auto;
		transition: color 400ms ease;
	}

	.Interior--isFocused {
		color: rgb(245, 245, 245);
	}

	p.reset {
		animation-duration: 700ms;
		animation-name: ${ TextWallFlash };
		animation-timing-function: ease;
		animation-fill-mode: backwards;
	}

	p {
		font-size: 21px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
		transform-origin: left top;
		will-change: opacity, transform;

		span {
			display: block;
			white-space: nowrap;
		}

		&::selection {
			background: white;
			color: rgb(235, 235, 235);
		}

		&::-moz-selection {
			background: white;
			color: rgb(235, 235, 235);
		}
	}

	a {
		color: rgb(242, 91, 49);
		${'' /* color: white; */}
		position: relative;
		text-decoration: none;
		transition: color 300ms ease;
		white-space: nowrap;

		&::before {
			background-color: rgba(242, 91, 49, 1);
			background: rgb(86, 86, 86) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAFCAYAAABirU3bAAAAHklEQVQYV2NcsGDB/4SEBEYGKIAzMARgKnGrgGkBAORbCAbV6b3oAAAAAElFTkSuQmCC) repeat;
			bottom: -0.2em;
			content: "";
			left: -0.2em;
			position: absolute;
			right: -0.2em;
			top: -0.2em;
			transform: rotate(0.7deg) scaleX(0);
			transform-origin: left center;
			transition: transform 300ms ease 50ms;
			z-index: -1;
		}

		&:focus,
		&:hover {
			color: white;
			outline: none;

			&::before {
				transform: rotate(0.7deg) scaleX(1);
			}
		}

		&::selection {
			background: white;
			color: rgb(242, 91, 49);
		}

		&::-moz-selection {
			background: white;
			color: rgb(242, 91, 49);
		}
	}

	@media (min-width: ${breakpoints.medium}px) {
		order: 1;
		width: 50vw;

		.Interior {
			bottom: 0;
			height: auto;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
`

class App extends Component {
	constructor() {
		super();

		this.state = {
			linkIsFocused: false,
			textWallClass: null,
			textWallPosition: '-50%, -50%, 0',
			textWallScale: 1,
		};

		this.InteriorRef = React.createRef()
		this.scaleTextWall = debounce(this.scaleTextWall, 700)
	}


	componentDidMount() {
		window.addEventListener('resize', this.scaleTextWall)
		this.calculateTextWallScale()
	}


	componentWillUnmount() {
		window.removeEventListener('resize', this.scaleTextWall)
	}


	/**
	 * Scale Text Wall based on available space
	 * @method scaleTextWall
	 * @return {Void}
	 */
	scaleTextWall = () => {
		// Clear timeout that handles clearing textWallClass class
		clearTimeout(this.textWallFlashReset)

		this.calculateTextWallScale()
	}


	calculateTextWallScale = () => {
		// Scale of TextWall shouldn’t go any lower than this
		const minScale = 0.9

		// Dimensions of the TextWall parent container
		const parent = this.InteriorRef.current.getBoundingClientRect()

		// TextWall reference and dimensions (ignoring current scale())
		const textWall = document.getElementById('TextWall')
		const textWallSize = {
			height: textWall.offsetHeight,
			width: textWall.offsetWidth,
		}

		// Calculate scale to fill visible area
		const scaleX = parent.width / textWallSize.width
		const scaleY = parent.height / textWallSize.height
		const scale = (scaleX > scaleY) ? scaleX : scaleY
		const textWallScale = scale < minScale ? minScale : scale

		// If the new scale is different than the previous…
		if (this.state.textWallScale !== textWallScale) {
			// Set TextWall class and transform
			this.setState({
				textWallClass: 'reset',
				textWallScale,
			})

			// Clear the textWallClass after 600ms
			this.textWallFlashReset = setTimeout(() => {
				this.setState({ textWallClass: null })
			}, 600)
		}
	}


	setMouseOverLink = () => {
		this.setState({ linkIsFocused: true })
	}


	setMouseOutLink = () => {
		this.setState({ linkIsFocused: false })
	}


	render() {
		const title = 'The New York City User Guide'
		const description = 'The New York City User Guide is a compendium of helpful guidelines for the ultimate enjoyment of New York City, all community driven and curated.'
		const ogImage = process.env.NODE_ENV === 'development' ? OpenGraphImage : `https://userguide.nyc${OpenGraphImage}`

		const Interior = ['Interior']
		if (this.state.linkIsFocused) {
			Interior.push('Interior--isFocused')
		}

		return (
			<Wrapper>
				<Helmet>
					<meta charSet="utf-8" />
					<title>{ title }</title>

					<meta content={ title } name="title" />
					<meta content={ description } name="description" />

					<meta content="website" property="og:type" />
					<meta content="https://userguide.nyc" property="og:url" />
					<meta content={ title } property="og:title" />
					<meta content={ description } property="og:description" />
					<meta content={ ogImage } property="og:image" />

					<meta content="summary_large_image" property="twitter:card" />
					<meta content="https://userguide.nyc" property="twitter:url" />
					<meta content={ title } property="twitter:title" />
					<meta content={ description } property="twitter:description" />
					<meta content={ ogImage } property="twitter:image" />
					<meta content="1311438482346325" property="fb:app_id" />
					<meta content="663447811" property="fb:admins" />
				</Helmet>
				<div className="Version">v{ version }</div>
				<Ticker>
					<div className={ Interior.join(' ') } ref={ this.InteriorRef }>
						<TextWall className={ this.state.textWallClass } transform={ `rotate(-0.5deg) scale(${this.state.textWallScale}) translate3d(-50%, -50%, 0)` } />
					</div>
				</Ticker>
				<Masthead />
			</Wrapper>
		)
	}
}

export default App
